import {
    IonButton,
    IonCheckbox,
    IonContent,
    IonDatetime,
    IonFab,
    IonFabButton,
    IonIcon,
    IonInput,
    IonItemDivider,
    IonLoading,
    IonModal,
    IonPopover,
    IonSelect,
    IonSelectOption
} from '@ionic/react';
import { checkmarkCircleOutline, checkmarkDone, closeOutline } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import { getPlanName } from '../../utils';
import { Organization, OrganizationLicense, OrganizationMembership } from '../store/reducers';
import "./LicenseDetailsPopover.css";
import { organizationServices } from '../store/services';
import { licensesServices } from '../../licenses/store/services';

type Props = {
    history: any,
    isOpen: boolean,
    license: OrganizationLicense | null,
    existingLicenses: OrganizationLicense[],
    orgMemberships: OrganizationMembership[] | null,
    orgData: Organization,

    onLicenseUpdated: (updatedLicenses: OrganizationLicense[]) => void,
    onLicenseDeletion: (license: OrganizationLicense) => void,
    onDismiss: () => void,
};

type State = {
    isLoading: boolean,
    showStartDateModal: boolean,
    showEndDateModal: boolean,

    updatedLicense: any | null,
    selectedMembers: OrganizationMembership[],
};

class LicenseDetailsPopover extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
            showStartDateModal: false,
            showEndDateModal: false,

            updatedLicense: null,
            selectedMembers: [],
        }
    }

    componentDidMount(): void {
    }

    updateLicenseDate(date: string) {
        if (this.state.showStartDateModal)
            this.setState({ updatedLicense: { ...this.state.updatedLicense!, start_at: Math.floor((new Date(date)).getTime() / 1000) } })
        else
            this.setState({ updatedLicense: { ...this.state.updatedLicense!, end_at: Math.floor((new Date(date)).getTime()) / 1000 } })
    }
    updateLicenseAmount(amount: number) {
        this.setState({ updatedLicense: { ...this.state.updatedLicense!, total_licenses: amount } })
    }
    updateAssignment(autoassignable: boolean) {
        this.setState({ updatedLicense: { ...this.state.updatedLicense!, autoassignable: autoassignable } })
    }

    formatDates(license: any) {
        let tempLicense = license
        if (typeof license.start_at === 'string') {
            const startDate = Math.floor((new Date(license.start_at)).getTime()) / 1000;
            license.start_at = startDate;
        }
        if (typeof license.end_at === 'string') {
            const endDate = Math.floor((new Date(license.end_at)).getTime()) / 1000;
            license.end_at = endDate;
        }
        return tempLicense

    }
    render() {
        return (
            <IonPopover
                isOpen={this.props.isOpen}
                onDidDismiss={() => {
                    this.setState({ updatedLicense: null })
                    this.props.onDismiss()
                }}
                className='licensesPopover'
            >
                <IonFab horizontal='end' vertical='top'>
                    <IonFabButton onClick={() => {
                        this.props.onDismiss()
                    }}>
                        <IonIcon
                            icon={closeOutline}
                        />
                    </IonFabButton>
                </IonFab>
                {this.props.license &&
                    <div className='licensesHandlerDiv'>
                        <div className='orgLicenseDetailsHeader'>
                            <p>{getPlanName(this.props.license.plan_id)}</p>
                        </div>
                        <p>License details</p>
                        <IonItemDivider />
                        <div className='orgLicenseDetailsContent'>
                            <div className='orgLicenseDetailsDiv'>
                                <div className='orgLicenseFieldDiv'>
                                    <p>Starting date:</p>
                                    <p
                                        className='orgLicenseDate'
                                        onClick={() => {
                                            this.setState({ showStartDateModal: true })
                                        }}
                                    >
                                        { /* THIS CHECK IS DONE BECAUSE THE BACKEND GIVES THE DATE IN STRING, BUT THE UPDATE MUST BE DONE IN NUMERIC TIMESTAMP -> SO IN THE TEMP LICENSE OBJECT WE SAVE IT AS A NUMBER TO BE SENT TO THE BACKEND */
                                            this.state.updatedLicense && !isNaN(this.state.updatedLicense.start_at) ?
                                                new Date(this.state.updatedLicense.start_at * 1000).toLocaleDateString()
                                                :
                                                new Date(this.props.license.start_at).toLocaleDateString()
                                        }
                                    </p>
                                </div>
                                <div className='orgLicenseFieldDiv'>
                                    <p>Ending date:</p>
                                    <p
                                        className='orgLicenseDate'
                                        onClick={() => {
                                            this.setState({ showEndDateModal: true })
                                        }}
                                    >
                                        { /* THIS CHECK IS DONE BECAUSE THE BACKEND GIVES THE DATE IN STRING, BUT THE UPDATE MUST BE DONE IN NUMERIC TIMESTAMP -> SO IN THE TEMP LICENSE OBJECT WE SAVE IT AS A NUMBER TO BE SENT TO THE BACKEND */
                                            this.state.updatedLicense && !isNaN(this.state.updatedLicense.end_at) ?
                                                new Date(this.state.updatedLicense.end_at * 1000).toLocaleDateString()
                                                :
                                                new Date(this.props.license.end_at).toLocaleDateString()
                                        }
                                    </p>
                                </div>
                                <div className='orgLicenseFieldDiv'>
                                    <p># of licenses:
                                    </p>
                                    <IonInput
                                        className='orgLicenseNumberInput'
                                        type='number'
                                        inputMode='numeric'
                                        min={0}
                                        value={this.state.updatedLicense ? this.state.updatedLicense.total_licenses : this.props.license.total_licenses}
                                        debounce={500}
                                        onInput={(e) => {
                                            if (this.state.updatedLicense) {
                                                this.updateLicenseAmount(Number((e.target as HTMLIonInputElement).value))
                                            }
                                            else {
                                                this.setState({ updatedLicense: this.props.license }, () => {
                                                    this.updateLicenseAmount(Number((e.target as HTMLIonInputElement).value))
                                                })
                                            }
                                        }}
                                    />
                                </div>
                                <div className='orgLicenseFieldDiv'>
                                    <p>Autoassignable</p>
                                    <IonCheckbox
                                        checked={this.state.updatedLicense ? this.state.updatedLicense.autoassignable : this.props.license.autoassignable}
                                        onClick={(e) => {
                                            if (this.state.updatedLicense) {
                                                this.updateAssignment(!this.state.updatedLicense.autoassignable)
                                            }
                                            else {
                                                this.setState({ updatedLicense: this.props.license }, () => {
                                                    this.updateAssignment(!this.state.updatedLicense.autoassignable)
                                                })
                                            }
                                        }}
                                    />
                                </div>
                                <div className='orgLicenseFieldDiv'>
                                    <p>Assign license to user</p>
                                    {this.props.orgMemberships && this.props.orgMemberships.length > 0 &&
                                        <IonSelect multiple value={this.state.selectedMembers} onIonChange={(e) => {
                                            this.setState({ selectedMembers: e.detail.value })

                                        }}>
                                            {this.props.orgMemberships.map(member => {
                                                return (
                                                    <IonSelectOption
                                                        key={member.id}
                                                        value={member}
                                                    >
                                                        {member.user_id}
                                                    </IonSelectOption>
                                                )
                                            })}
                                        </IonSelect>
                                    }
                                    <IonIcon
                                        style={{ fontSize: '32px' }}
                                        icon={checkmarkCircleOutline}
                                        onClick={() => {
                                            for (let i = 0; i < this.state.selectedMembers.length; i++) {
                                                const member = this.state.selectedMembers[i];
                                                organizationServices.getMemberLicenses(member.org_id, member.user_id).then(licenses => {
                                                    if (licenses.filter(lic => lic.id === this.props.license?.id).length === 0) {
                                                        organizationServices.assignLicenseToMember(this.props.orgData.id, member.user_id, this.state.updatedLicense ? this.state.updatedLicense.id : this.props.license?.id)
                                                            .then(response => {
                                                                console.log("ASSIGNED LICENSE TO: ", member.user_id);

                                                            })
                                                    }
                                                    else {
                                                        console.log("LICENSE ALREADY ASSIGNED TO: ", member.user_id);
                                                    }
                                                })
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className='orgLicenseButtonDiv'>
                                <IonButton
                                    color='danger'
                                    onClick={() => {
                                        if (this.props.license)
                                            organizationServices.deleteOrgLicense(this.props.license.org_id, this.props.license.id)
                                                .then(response => {
                                                    this.props.onLicenseDeletion(this.props.license!)
                                                    this.props.onDismiss();
                                                })
                                    }}
                                >
                                    Remove License
                                </IonButton>

                                <IonButton
                                    color='success'
                                    onClick={() => {
                                        if (this.state.updatedLicense) {
                                            this.setState({ updatedLicense: this.formatDates(this.state.updatedLicense) }, () => {
                                                if (this.state.updatedLicense.start_at > this.state.updatedLicense.end_at) {
                                                    alert("Starting date is greater than ending date. Please correct this.")
                                                    return
                                                }
                                                organizationServices.updateOrgLicense(
                                                    this.state.updatedLicense.org_id,
                                                    this.state.updatedLicense.id,
                                                    this.state.updatedLicense.total_licenses,
                                                    this.state.updatedLicense.start_at,
                                                    this.state.updatedLicense.end_at,
                                                    this.state.updatedLicense.autoassignable,
                                                    this.props.existingLicenses
                                                ).then(response => {
                                                    this.props.onLicenseUpdated(response.updatedLicenses ? response.updatedLicenses : [response]);
                                                    this.props.onDismiss()
                                                })
                                            })
                                        }
                                    }}
                                >Confirm Changes
                                </IonButton>
                            </div>
                        </div>
                        <IonLoading isOpen={this.state.isLoading} message={"Loading data..."} />



                        <IonModal
                            isOpen={this.state.showStartDateModal || this.state.showEndDateModal}
                            onDidDismiss={() => {
                                this.setState({ showStartDateModal: false, showEndDateModal: false });
                            }}
                        >
                            <IonContent>
                                <p style={{ textAlign: 'center' }}>{this.state.showStartDateModal ? "License start date" : "License end date"}</p>
                                <IonDatetime
                                    min={
                                        this.state.updatedLicense ?
                                            new Date(this.state.updatedLicense.start_at).toISOString() :
                                            new Date(this.props.license.start_at).toISOString()
                                    }
                                    max={new Date(new Date().setFullYear(new Date().getFullYear() + 5)).toISOString()}
                                    onIonChange={e => {
                                        if (e.detail.value) {
                                            if (this.state.updatedLicense) {
                                                this.updateLicenseDate(e.detail.value)
                                            }
                                            else {
                                                this.setState({ updatedLicense: this.props.license }, () => {
                                                    this.updateLicenseDate(e.detail.value!)
                                                })
                                            }
                                        }
                                        setTimeout(() => {
                                            console.log("UPDATED LICENSE: ", this.state.updatedLicense);

                                        }, 1000);
                                    }}
                                />

                                <IonButton
                                    onClick={() => {
                                        this.setState({ showStartDateModal: false, showEndDateModal: false });
                                    }}
                                >
                                    Done {/* TO BE LOCALIZED */}
                                </IonButton>
                            </IonContent>
                        </IonModal>
                    </div>
                }
            </IonPopover >
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LicenseDetailsPopover);