import {
    IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonContent, IonGrid, IonHeader, IonPage, IonSearchbar, IonSpinner, IonTitle, IonToolbar
} from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { connect } from 'react-redux';
import MenuTabBar from '../../../common/TabBar/MenuTabBar';
import { auth, functions } from '../../../firebaseConfig';
import "./ReasyHome.css";
import apiService from '../../../apiService';

interface ReasyUser {
    id: string,
    email: string,
    fist_name: string | null,
    last_name: string | null,
}

type Props = {
    history: any,
};

type State = {
    reasyUsers: ReasyUser[],
    isLoadingUsersData: boolean,
    searchText: string | undefined,
};

class ReasyHome extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            reasyUsers: [],
            isLoadingUsersData: false,
            searchText: undefined,
        }
    }

    componentDidMount(): void {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                // const getReasyUsersData = httpsCallable(functions, "getReasyUsersData");
                // getReasyUsersData()
                // .then(res => {
                //     console.log("[ReasySummary] response:", res.data);
                //     if((res.data as any).error) {
                //         console.error("[ReasySummary] error returned from cloud function:", (res.data as any).error);
                //     }
                //     else if((res.data as any).users) {
                //         this.setState({reasyUsers: (res.data as any).users as ReasyUser[]});
                //     }
                // })
                // .catch(err => {
                //     console.error("[ReasySummary] error calling cloud function:", err);
                //     this.setState({ isLoadingUsersData: false });
                // });


                this.setState({ isLoadingUsersData: true });
                apiService.get("/users")
                    .then(res => {
                        console.log("USERS:", res)
                        this.setState({
                            reasyUsers: res as ReasyUser[],
                            isLoadingUsersData: false
                        });
                    })
                    .catch(err => {
                        console.log("Error calling backend:", err)
                        this.setState({ isLoadingUsersData: false });
                    })
            }
        })
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>
                            Reasy
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid className="reasyHomeMainGrid">
                        <div className="reasyHomeLicensesManagerDiv">
                            <IonButton
                                className="reasyHomeLicensesManagerButton"
                                onClick={() => {
                                    this.props.history.push("/reasy/licenses-manager");
                                }}
                            >
                                Licenses management {/* TO BE LOCALIZED */}
                            </IonButton>
                            <IonButton
                                className="reasyHomeLicensesManagerButton"
                                fill="outline"
                                onClick={() => {
                                    this.props.history.push("/reasy/new-user");
                                }}
                            >
                                Sign up new user {/* TO BE LOCALIZED */}
                            </IonButton>

                            <IonButton
                                className="reasyHomeLicensesManagerButton"
                                fill="outline"
                                onClick={() => {
                                    this.props.history.push("/reasy/pending-user");
                                }}
                            >
                                Create pending user {/* TO BE LOCALIZED */}
                            </IonButton>
                        </div>

                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>
                                    Users {/* TO BE LOCALIZED */}
                                </IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>

                                {
                                    this.state.reasyUsers.length !== 0 &&
                                    <div className="reasyHomeUsersCounterDiv">
                                        Gli utenti sono: {this.state.reasyUsers.length}. {/* TO BE LOCALIZED */}
                                    </div>
                                }

                                <IonSearchbar
                                    className="reasyHomeSearchbar"
                                    value={this.state.searchText}
                                    placeholder="Email" /* TO BE LOCALIZED */
                                    onIonChange={e => {
                                        this.setState({ searchText: e.detail.value ? e.detail.value : undefined });
                                    }}
                                />

                                {
                                    this.state.isLoadingUsersData &&
                                    <div className="reasyHomeSpinnerDiv">
                                        <IonSpinner />
                                    </div>
                                }


                                {
                                    !this.state.isLoadingUsersData &&
                                    <table role="grid">
                                        <thead
                                            className="reasyHomeUsersCardRowDiv"
                                            role="rowgroup"
                                        >
                                            <tr role="row">
                                                <td
                                                    role="gridcell"
                                                    className="reasyHomeUsersCardEmailColPar">
                                                    <b>Email</b> {/* TO BE LOCALIZED */}
                                                </td>
                                                {/* <td role="gridcell">
                                                    <b>N° di accessi</b>
                                                </td> */}
                                            </tr>
                                        </thead>

                                        <tbody role="rowgroup">
                                            {
                                                this.state.reasyUsers
                                                    .filter((user) =>
                                                        user.email ? user.email.toLowerCase().includes(this.state.searchText ? this.state.searchText.toLowerCase() : "") : ""
                                                    )
                                                    .map(user => {
                                                        return (

                                                            <tr
                                                                role="row"
                                                                className="reasyHomeUsersCardRowDiv"
                                                                key={user.id}
                                                                onClick={() => {
                                                                    this.props.history.push(`/reasy/user?id=${user.id}`)
                                                                }}
                                                            >
                                                                <td
                                                                    className="reasyHomeUsersCardEmailColPar"
                                                                    role="gridcell"
                                                                >
                                                                    {user.email}
                                                                </td>

                                                            </tr>
                                                        )
                                                    })
                                            }
                                        </tbody>
                                    </table>
                                }

                            </IonCardContent>

                        </IonCard>
                    </IonGrid>
                </IonContent>

                <MenuTabBar />
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReasyHome);