import {
    IonButton,
    IonContent,
    IonDatetime,
    IonFab,
    IonFabButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonModal,
    IonPopover,
    IonSelect,
    IonSelectOption
} from '@ionic/react';
import { addOutline, closeOutline, pencilOutline, trashSharp } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import { Plan, UserLicense } from '../../licenses/store/reducers';
import { getPlanName } from '../../utils';
import { userServices } from '../store/services';
import "./HandleLicensesPopover.css";
import { licensesServices } from '../../licenses/store/services';

type Props = {
    history: any,
    userId: string,
    isOpen: boolean,
    licenses: UserLicense[],


    onDismiss: () => void,
    onLicenseAdded: (license: UserLicense) => void,
    onLicenseDeleted: (licenseId: string) => void,
};

type State = {
    addLicenseMode: boolean,
    licensesEditingMode: boolean,

    allPlans: Plan[]
    selectedPlan: Plan | null,

    licenseStartDate: number | null,
    licenseEndDate: number | null,
    isStartDateModalOpen: boolean,
    isEndDateModalOpen: boolean,

    showRemovalConfirmationPopover: boolean,
    licenseToRemove: UserLicense | null,

    isLoading: boolean,
};

class HandleLicensesPopover extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            addLicenseMode: false,
            licensesEditingMode: false,

            allPlans: [],
            selectedPlan: null,

            licenseStartDate: null,
            licenseEndDate: null,
            isStartDateModalOpen: false,
            isEndDateModalOpen: false,

            showRemovalConfirmationPopover: false,
            licenseToRemove: null,

            isLoading: false,
        }
    }

    componentDidMount(): void {
    }

    render() {
        return (
            <IonPopover
                isOpen={this.props.isOpen}
                onDidDismiss={() => {
                    this.setState({ addLicenseMode: false })
                    this.props.onDismiss()
                }}
                className='licensesPopover'
            >
                <IonFab horizontal='end' vertical='top'>
                    <IonFabButton onClick={() => {
                        this.setState({ addLicenseMode: false })
                        this.props.onDismiss()
                    }}>
                        <IonIcon
                            icon={closeOutline}
                        />
                    </IonFabButton>
                </IonFab>
                <div className='licensesHandlerDiv'>
                    <div className='licensesHandlerButtonsDiv'>
                        <div
                            className='licensesEditingButton'
                            onClick={() => {
                                if (!this.state.addLicenseMode) {
                                    if (this.state.allPlans.length === 0) {
                                        this.setState({ isLoading: true })
                                        licensesServices.getAllPlans().then(plans => {
                                            this.setState({
                                                addLicenseMode: !this.state.addLicenseMode,
                                                allPlans: plans,
                                                selectedPlan: plans[0],
                                                isLoading: false
                                            }) /* HANDLE */
                                        })
                                    }
                                    else {
                                        this.setState({ selectedPlan: this.state.allPlans[0], addLicenseMode: !this.state.addLicenseMode })
                                    }
                                }
                                else
                                    this.setState({ addLicenseMode: !this.state.addLicenseMode })
                            }}
                        >
                            <IonLabel>
                                {this.state.addLicenseMode ?
                                    "Cancel" : "Add new license"
                                }
                            </IonLabel>
                            <IonIcon
                                icon={this.state.addLicenseMode ? closeOutline : addOutline}
                            />
                        </div>
                        <div
                            hidden={this.state.addLicenseMode}
                            className='licensesEditingButton'
                            onClick={() => {
                                this.setState({ licensesEditingMode: !this.state.licensesEditingMode })
                            }}
                        >
                            <IonLabel>Edit licenses</IonLabel>
                            <IonIcon
                                icon={pencilOutline}
                            />
                        </div>
                    </div>
                    <div className='licensesHandlerContentDiv'>
                        {!this.state.addLicenseMode &&
                            this.props.licenses.map(license => {
                                return (
                                    <IonItem className='userDetailProductItem'>
                                        {
                                            <IonButton hidden={!this.state.licensesEditingMode} color='danger' onClick={() => {
                                                userServices.deleteLicenseFromUser(this.props.userId, license.id)
                                                    .then(response => {
                                                        this.props.onLicenseDeleted(license.id)
                                                    })
                                                    .catch(err => {

                                                    })
                                            }}>
                                                <IonIcon icon={trashSharp} />
                                            </IonButton>

                                        }
                                        <div
                                            key={license.id}
                                            className="organizationDetailProductItemDiv"
                                        >
                                            <p className="organizationDetailProductItemName">
                                                {getPlanName(license.plan_id)}
                                            </p>

                                            <p>
                                                Expiration on: {(new Date(license.end_at)).toLocaleDateString()}.
                                            </p>
                                        </div>
                                    </IonItem>
                                )
                            })}
                        {this.state.addLicenseMode && this.state.allPlans.length > 0 &&
                            <div className='addLicenseDiv'>
                                <IonItem className='addLicensePlanItem'>
                                    <IonLabel>Plan</IonLabel>
                                    <IonSelect value={this.state.selectedPlan} onIonChange={(e) => this.setState({ selectedPlan: e.detail.value })}>
                                        {this.state.allPlans.map(plan => {
                                            return (
                                                <IonSelectOption value={plan}>{plan.name}</IonSelectOption>
                                            )
                                        })}
                                    </IonSelect>
                                </IonItem>
                                <IonItem className='addLicensePlanItem'>
                                    <IonLabel
                                        onClick={() => {
                                            this.setState({ isStartDateModalOpen: true })
                                        }}>
                                        Starting date:
                                        {
                                            this.state.licenseStartDate ?
                                                " " + new Date(this.state.licenseStartDate * 1000).toLocaleDateString() :
                                                <IonButton onClick={() => {
                                                    this.setState({ isStartDateModalOpen: true })
                                                }}
                                                >
                                                    Set
                                                </IonButton>
                                        }
                                    </IonLabel>
                                    <IonModal
                                        isOpen={this.state.isStartDateModalOpen}
                                        onDidDismiss={() => {
                                            this.setState({ isStartDateModalOpen: false });
                                        }}
                                    >
                                        <IonContent>
                                            <IonDatetime
                                                min={new Date().toISOString()}
                                                max={new Date(new Date().setFullYear(new Date().getFullYear() + 5)).toISOString()}
                                                onIonChange={e => {
                                                    this.setState({ licenseStartDate: e.detail.value ? Math.floor((new Date(e.detail.value)).getTime() / 1000) : null });
                                                }}
                                            />

                                            <IonButton
                                                onClick={() => {
                                                    this.setState({ isStartDateModalOpen: false });
                                                }}
                                            >
                                                Done {/* TO BE LOCALIZED */}
                                            </IonButton>
                                        </IonContent>
                                    </IonModal>
                                </IonItem>
                                <IonItem className='addLicensePlanItem'>
                                    <IonLabel
                                        onClick={() => {
                                            this.setState({ isEndDateModalOpen: true })
                                        }}>
                                        Ending date:
                                        {
                                            this.state.licenseEndDate ?
                                                " " + new Date(this.state.licenseEndDate * 1000).toLocaleDateString() :
                                                <IonButton onClick={() => {
                                                    this.setState({ isEndDateModalOpen: true })
                                                }}
                                                >
                                                    Set
                                                </IonButton>
                                        }
                                    </IonLabel>
                                    <IonModal
                                        isOpen={this.state.isEndDateModalOpen}
                                        onDidDismiss={() => {
                                            this.setState({ isEndDateModalOpen: false });
                                        }}
                                    >
                                        <IonContent>
                                            <IonDatetime
                                                min={new Date().toISOString()}
                                                max={new Date(new Date().setFullYear(new Date().getFullYear() + 5)).toISOString()}
                                                onIonChange={e => {
                                                    this.setState({ licenseEndDate: e.detail.value ? Math.floor((new Date(e.detail.value)).getTime() / 1000) : null });
                                                }}
                                            />

                                            <IonButton
                                                onClick={() => {
                                                    this.setState({ isEndDateModalOpen: false });
                                                }}
                                            >
                                                Done {/* TO BE LOCALIZED */}
                                            </IonButton>
                                        </IonContent>
                                    </IonModal>
                                </IonItem>
                                <IonButton
                                    onClick={() => {
                                        if (this.state.licenseStartDate && this.state.licenseEndDate && this.state.selectedPlan) {
                                            if (this.state.licenseStartDate < this.state.licenseEndDate) {
                                                userServices.addLicenseToUser(this.props.userId, this.state.selectedPlan?.id, this.state.licenseStartDate, this.state.licenseEndDate)
                                                    .then(response => {
                                                        this.props.onLicenseAdded(response)
                                                    })
                                            }
                                            else
                                                alert("Starting date is greater than ending date")
                                        }
                                        else {
                                            alert("Make sure to set all the date values")
                                        }
                                    }}
                                >
                                    Add license
                                </IonButton>
                            </div>
                        }
                    </div>
                    <IonLoading isOpen={this.state.isLoading} message={"Loading data..."} />
                </div>
            </IonPopover >
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HandleLicensesPopover);